<template>
  <div class="signup">
    <transition name="fade" mode="out-in">
      <!-- email step -->
      <email-step v-if="step == 1" @next="handleStepEmail"></email-step>

      <!-- verify step -->
      <verify-step
        :key="step"
        v-if="step == 2"
        @next="handleStepVerify"
        :emailObj="obj"
      ></verify-step>

      <!-- password step -->
      <password-step
        :key="step"
        v-if="step == 3"
        @next="handleStepPassword"
        :emailObj="obj"
      ></password-step>
    </transition>

    <div class="text-center mt-10">
      <p class="greytext--text font-18">
        {{ $t("Already have an account?") }}
        <router-link to="/register/login"
          ><span class="font-18 darkprimary--text cursor-pointer font-700"
            >{{$t("heroPage.loginNow")}}</span
          ></router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import EmailStep from "./steps/Email";
import PasswordStep from "./steps/Password";
import VerifyStep from "./steps/Verify";
export default {
  components: {
    EmailStep,
    PasswordStep,
    VerifyStep,
  },
  data: () => ({
    step: 1,
    obj: {},
  }),
  methods: {
    handleStepEmail(obj) {
      this.obj = obj;
      this.step = 2;
    },
    handleStepVerify() {
      this.step = 3;
    },
  },
};
</script>

<style></style>
